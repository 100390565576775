import React, { useState } from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import WeeklyMenu from "../components/restaurant-menu/weekly-menu"

const MenuPage = (props) => {
	const seoDescription = "Per celebrar el 50è aniversari de casa nostra, de dimarts a divendres us proposem un menú on podreu tastar plats de temporada que ens han acompanyat al llarg de tots aquests anys.";
	const entrants = "Entrants";
	const platsPrincipals = "Plats Principals";
	const postres = "Postres";
	const ivaInclos = "IVA inclòs";
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Menu d'entre setmana"
	    	lang="ca"
	    	description={seoDescription}
	    />
	    <HeroImage 
	      titleText="Menú d'entre setmana"
	      image="carta" 
	    />
	    <WeeklyMenu 
	    	lang='ca'
	    	entrants={entrants}
	    	principals={platsPrincipals}
	    	postres={postres}
	    	iva={ivaInclos}
	    />
	    <Link to="/carta">
	    	<h2>Veure la Carta</h2>
	    </Link>
	  </Layout>
	)
}

const MenuSection = styled.div`
	margin: 140px 70px;
	display: flex;
	@media (max-width: 540px){
		margin: 100px 5px;
	  flex-direction: column;
	}
`

const SelectBox = styled.select`
	font-size: 16px;
	@media (min-width: 540px){
	  display: none;
	}
`

const MenuHeaders = styled.div`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	width: 250px;
	li {
	  cursor: pointer;
	  padding: 10px;
	  line-height: 20px;
	}
	@media (max-width: 540px){
	  display: none;
	}
`

export default MenuPage