import React from 'react'
import styled from 'styled-components'
import {useStaticQuery, graphql} from 'gatsby'

const WeeklyMenu = (props) => {
  const data = useStaticQuery(graphql`
  query {
    ca: prismicMenuSetmanal(lang: {eq: "ca"}) {
      id
      data {
        title {
          text
        }
        description {
          text
        }
        entrants {
          nom_del_plat {
            text
          }
        }
        plats_principals {
          nom_del_plat {
            text
          }
        }
        postres {
          nom_del_plat {
            text
          }
        }
        cafes_vi_o_cava {
          nom_de_l_extra {
            text
          }
        }
        preu_del_menu
      }
    }
    es: prismicMenuSetmanal(lang: {eq: "es-es"}) {
      id
      data {
        title {
          text
        }
        description {
          text
        }
        entrants {
          nom_del_plat {
            text
          }
        }
        plats_principals {
          nom_del_plat {
            text
          }
        }
        postres {
          nom_del_plat {
            text
          }
        }
        cafes_vi_o_cava {
          nom_de_l_extra {
            text
          }
        }
        preu_del_menu
      }
    }
    en: prismicMenuSetmanal(lang: {eq: "en-gb"}) {
      id
      data {
        title {
          text
        }
        description {
          text
        }
        entrants {
          nom_del_plat {
            text
          }
        }
        plats_principals {
          nom_del_plat {
            text
          }
        }
        postres {
          nom_del_plat {
            text
          }
        }
        cafes_vi_o_cava {
          nom_de_l_extra {
            text
          }
        }
        preu_del_menu
      }
    }
    fr: prismicMenuSetmanal(lang: {eq: "fr-fr"}) {
      id
      data {
        title {
          text
        }
        description {
          text
        }
        entrants {
          nom_del_plat {
            text
          }
        }
        plats_principals {
          nom_del_plat {
            text
          }
        }
        postres {
          nom_del_plat {
            text
          }
        }
        cafes_vi_o_cava {
          nom_de_l_extra {
            text
          }
        }
        preu_del_menu
      }
    }
  }
  `)

  const labelEntrants = props.entrants;
  const labelPrincipals = props.principals;
  const labelPostres = props.postres;
  const title = data[props.lang].data.title;
  const description = data[props.lang].data.description;
  const entrants = data[props.lang].data.entrants;
  const plats_principals = data[props.lang].data.plats_principals;
  const postres = data[props.lang].data.postres;
  const cafes_vi_o_cava = data[props.lang].data.cafes_vi_o_cava;
  const preu_del_menu = data[props.lang].data.preu_del_menu;
  return (
    <ListItems>
      <MenuItemsGrid>
        <MenuItem>
          <h1>{title[0].text}</h1>
          <span>{description[0].text}</span>
        </MenuItem>
        <MenuItem>
          <h2>{labelEntrants}</h2>
          {
            entrants.map((node, i) => {
              const { nom_del_plat } = node;
              return (
                <Dish key={`menu_entr_${i}`}>
                  <h3>{nom_del_plat[0].text}</h3>
                </Dish>
              )
            })
          }
          <h2>{labelPrincipals}</h2>
          {
            plats_principals.map((node, i) => {
              const { nom_del_plat } = node;
              return (
                <Dish key={`menu_princi_${i}`}>
                  <h3>{nom_del_plat[0].text}</h3>
                </Dish>
              )
            })
          }
          <h2>{labelPostres}</h2>
          {
            postres.map((node, i) => {
              const { nom_del_plat } = node;
              return (
                <Dish key={`menu_postres_${i}`}>
                  <h3>{nom_del_plat[0].text}</h3>
                </Dish>
              )
            })
          }
          <h2>{cafes_vi_o_cava[0].nom_de_l_extra[0].text}</h2>
          <h1>{preu_del_menu}€ ({props.iva})</h1>
        </MenuItem>
      </MenuItemsGrid>
    </ListItems>
  )
}

const ListItems = styled.div`
  font-size: 18px;
  @media (max-width: 540px){
    font-size: 14px;
  }
`

const MenuItemsGrid = styled.ul`
  list-style-type: none;
`

const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
`

const Dish = styled.div`
  display: flex;
`

const Price = styled.span`
  display: flex;
  align-items: center;
  margin: 15px;
  @media (max-width: 540px){
    margin: 5px;
  }
`

export default WeeklyMenu;
