module.exports = {
	/////// NAVIGATION////////
	"origensLink": "/origens",
	"origensLabel": "Origens",
	"cuinaLink": "/cuina",
	"cuinaLabel": "Cuina",
	"espaisLink": "/espais",
	"espaisLabel": "Espais",
	"cellerLink": "/celler",
	"cellerLabel": "Celler",
	"cartaLink": "/carta",
	"cartaLabel": "Carta",
	"noticiesLink": "/noticies",
	"noticiesLabel": "Notícies",
	"contacteLink": "/contacte",
	"contacteLabel": "Contacte",
	"reservesLink": "/reserves",
	"reservesLabel": "Reserves",

	/////// FOOTER////////
	"direccioCarrer": "Ponent 20",
	"direccioPoblacio": "Sant Pau d'Ordal 08739 BCN",
	"labelGoogleMaps": "google maps",
	"numTelefon":"+34 93 899 30 28",
	"email": "info@calperedelmaset.com",
	"diesDinarOberts": "Dimarts a Diumenge",
	"horesDinarOberts": "13:00 a 16:00",
	"diesSoparOberts": "Divendres i Dissabte",
	"horesSoparOberts": "21:00h a 23:00h",
	"diaTancat": "Dilluns tancat",
	"segueixnos": "SEGUEIX-NOS",
	"treballaAmbNosaltres": "Treballa amb nosaltres",
	"privacitat": "PRIVACITAT",
	"condicionsDus": "CONDICIONS D'ÚS",

	/////// ORIGENS////////
	"heroText": "Origens",
	"sentenceText": "Tot comença el juny de 1969",
	"contentCaption": "Un entorn inigualable i una privacitat absoluta",
	"contentTitle": "Benvingut a Cal Pere del Maset, una antiga masia situada a la zona tranquil.la de Sant Pau d'Ordal",
	"contentContent": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
	"textSectionText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
}